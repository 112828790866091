import React from 'react';
import './StoreButton.scss';
import { ReactComponent as GooglePlay } from "../../assets/icons/googleplay.svg";
import { ReactComponent as AppStore } from "../../assets/icons/appstore.svg";

interface StoreButtonProps {
    titleColor: string;
    subtitleColor: string;
    customOrder?: boolean;
}

const StoreButton: React.FC<StoreButtonProps> = ({ titleColor, subtitleColor, customOrder }) => {

    return (
        <div className='btn_wrapper'>
            <a href='https://play.google.com/store/apps/details?id=com.tekholitka.missionSM' target="_blank" rel="noopener noreferrer">
                <GooglePlay className='btn_wrapper-google'/>
            </a>
            <a href='https://apps.apple.com/si/app/mission-social-media/id6463722610' target="_blank" rel="noopener noreferrer">
                <AppStore className='btn_wrapper-appstore'/>
            </a>
        </div>
    );
};

export default StoreButton;
