import { useState, useEffect } from "react";
import "./MainPage.scss";
import StoreButton from "../../components/StoreButton/StoreButton";
import iphoneIcon from "../../assets/images/iphonefullicon.png";
import M1 from "../../assets/images/m1.png";
import M2 from "../../assets/images/m02.png";
import M3 from "../../assets/images/m03.png";
import M4 from "../../assets/images/m04.png";
import M5 from "../../assets/images/m05.png";

const icons = [M1, M2, M3, M4, M5];

const MainPage = () => {
  const [currentIconIndex, setCurrentIconIndex] = useState(0);

  useEffect(() => {
    const changeIcon = () => {
      setCurrentIconIndex((prevIndex) => (prevIndex + 1) % icons.length);
    };

    const intervalId = setInterval(changeIcon, 500);
    const pauseTimeoutId = setTimeout(() => {
      clearInterval(intervalId);
      const newIntervalId = setInterval(changeIcon, 600);
      return () => clearInterval(newIntervalId);
    }, 2500);

    return () => {
      clearInterval(intervalId);
      clearTimeout(pauseTimeoutId);
    };
  }, []);

  const CurrentIcon = icons[currentIconIndex];

  return (
    <div style={{ position: "relative" }}>
      <div className="main-page">
        <div className="main-page-inner">
          <header className="main-page__header">
            <img
              src={CurrentIcon}
              alt="Icon"
              className="main-page__header-logo"
            />
            <h1 className="main-page__header-title">
              Mission:{" "}
              <span className="main-page__header-subtitle">
                Speak to the World
              </span>
            </h1>
          </header>
          <div className="main-page__content">
            <p className="main-page__content-text">
              Uniting&nbsp;people and&nbsp;countries in&nbsp;the&nbsp;world
              without&nbsp;borders
            </p>
            <p className="main-page__content-textsmall">
              Social network with <span>✨AI</span>. Text, voice and&nbsp;video
              messages on&nbsp;your&nbsp;language.
            </p>
          </div>
          <div className="main-page__footer-buttons">
            <StoreButton titleColor="white" subtitleColor="white" />
          </div>
        </div>
        <div className="main-page__message">
          <img
            src={iphoneIcon}
            className="main-page__message-bluricon"
            alt="VoiceBlur"
          />
        </div>
        <div className="footer">
          <div className="footer-inner">
            <div className="footer-text">
              <span className="footer-title">
                2024 © TEKHNOLITIKA IT PRIVATE LIMITED
              </span>
              <span className="footer-subtitle">
                India, HD-211, PRESTIGE ATLANTA 10, 7 MAIN 80FT RD, 3RD BLOCK,
                Koramangala I Block Bangalore South Bangalore – 560034
              </span>
            </div>
            <div className="footer-btn">
              <a href="mailto:hello@mission.mn">Contact Us</a>
            </div>
          </div>
        </div>
      </div>
      <div className="main-page__bgpurple" />
    </div>
  );
};

export default MainPage;
